// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as AgencyAgent from "../../../models/AgencyAgent.res.js";

function exec(pageNum, sortBy, query) {
  var page = AgencyAgent.Dashboard.Index.page(pageNum);
  var query$1 = AgencyAgent.Dashboard.Index.query(query);
  var sortBy$1 = AgencyAgent.Dashboard.Index.sortBy(sortBy);
  return Rest.$$fetch("/dashboard/agency_agents?" + page + query$1 + sortBy$1, "Get", {
              NAME: "Json",
              VAL: AgencyAgent.Dashboard.Index.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* Rest Not a pure module */
