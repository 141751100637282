// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../styleguide/components/Link/A.res.js";
import * as Routes_Agency from "../../../../routes/common/Routes_Agency.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardNavbarCss from "../../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";

function DashboardAgencyAgentNavbar(props) {
  var __currentLink = props.currentLink;
  var currentLink = __currentLink !== undefined ? __currentLink : "Index";
  var linkCss = function (link) {
    if (link === currentLink) {
      return DashboardNavbarCss.underlinedLinkContainer;
    } else {
      return DashboardNavbarCss.linkContainer;
    }
  };
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_Agency.Dashboard.index,
                                className: DashboardNavbarCss.underlinedLinkItem,
                                children: "Agents"
                              }),
                          className: linkCss("Index")
                        }),
                    className: DashboardNavbarCss.barContainer
                  }),
              className: DashboardNavbarCss.barWrapper
            });
}

var Css;

var make = DashboardAgencyAgentNavbar;

export {
  Css ,
  make ,
}
/* A Not a pure module */
