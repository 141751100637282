// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Table from "../../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../styleguide/icons/IconMenu.res.js";
import * as TableRow from "../../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as TableBody from "../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Routes_Tsd from "../../../../routes/common/Routes_Tsd.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../../styleguide/icons/IconSorting.res.js";
import * as Routes_User from "../../../../routes/common/Routes_User.res.js";
import * as SearchField from "../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../../styleguide/components/Table/TableHeader.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Routes_Agency from "../../../../routes/common/Routes_Agency.res.js";
import * as Api_AgencyAgent from "../../../../api/agency-agents/Api_AgencyAgent.res.js";
import * as TableHeaderCell from "../../../../styleguide/components/Table/TableHeaderCell.res.js";
import * as DashboardIndexCss from "../../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_AgencyAgent from "../../../../routes/common/Routes_AgencyAgent.res.js";
import * as DashboardExportModal from "../../../../styleguide/dashboard/components/modals/export-csv/DashboardExportModal.res.js";
import * as DashboardAgencyAgentNavbar from "../common/DashboardAgencyAgentNavbar.res.js";
import * as DashboardAgencyAgentsOptions from "./components/DashboardAgencyAgentsOptions.res.js";

var initialState = {
  status: "FetchingAgencyAgents",
  currentPage: 1,
  totalPages: 1,
  sortBy: {
    NAME: "UserLastName",
    VAL: "Asc"
  },
  search: "",
  csvModalOpen: false
};

function DashboardAgencyAgentsIndex$default(props) {
  var container = React.useRef(null);
  var searchAgencies = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchAgencyAgents" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api_AgencyAgent.Dashboard.index(state.currentPage, state.sortBy, tmp), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedAgencyAgentsFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardAgencyAgentsIndex",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "DashboardAgencyAgentsIndex.default"
                                          }, "Dashboard::FetchAgencyAgents::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailAgencyAgentsFetch");
                                    }));
                            })
                        };
              case "FailAgencyAgentsFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedAgenciesFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvModalOpen: state.csvModalOpen
                          }
                        };
              case "ToggleCsvModalOpen" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvModalOpen: !state.csvModalOpen
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedAgencyAgentsFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.agencyAgents
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingAgencyAgents") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingAgencyAgents",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              csvModalOpen: state.csvModalOpen
                            },
                            _1: (function (param) {
                                param.dispatch("FetchAgencyAgents");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var sort = action._0;
                  var match$1 = state.status;
                  if (typeof match$1 !== "object" && match$1 === "FetchingAgencyAgents") {
                    return "NoUpdate";
                  }
                  var tmp;
                  if (sort === "TsdName") {
                    var match$2 = state.sortBy;
                    tmp = typeof match$2 === "object" && match$2.NAME === "TsdName" && match$2.VAL === "Desc" ? ({
                          NAME: "TsdName",
                          VAL: "Asc"
                        }) : ({
                          NAME: "TsdName",
                          VAL: "Desc"
                        });
                  } else if (sort === "AgencyName") {
                    var match$3 = state.sortBy;
                    tmp = typeof match$3 === "object" && match$3.NAME === "AgencyName" && match$3.VAL === "Desc" ? ({
                          NAME: "AgencyName",
                          VAL: "Asc"
                        }) : ({
                          NAME: "AgencyName",
                          VAL: "Desc"
                        });
                  } else if (sort === "UserLastName") {
                    var match$4 = state.sortBy;
                    tmp = typeof match$4 === "object" && match$4.NAME === "UserLastName" && match$4.VAL === "Desc" ? ({
                          NAME: "UserLastName",
                          VAL: "Asc"
                        }) : ({
                          NAME: "UserLastName",
                          VAL: "Desc"
                        });
                  } else {
                    var match$5 = state.sortBy;
                    tmp = typeof match$5 === "object" && match$5.NAME === "UserFirstName" && match$5.VAL === "Desc" ? ({
                          NAME: "UserFirstName",
                          VAL: "Asc"
                        }) : ({
                          NAME: "UserFirstName",
                          VAL: "Desc"
                        });
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingAgencyAgents",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: tmp,
                            search: state.search,
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: (function (param) {
                              param.dispatch("FetchAgencyAgents");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: searchAgencies
                        };
              case "PerformSearch" :
                  var match$6 = state.status;
                  if (typeof match$6 !== "object" && match$6 === "FetchingAgencyAgents") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingAgencyAgents",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              csvModalOpen: state.csvModalOpen
                            },
                            _1: (function (param) {
                                param.dispatch("FetchAgencyAgents");
                              })
                          };
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchAgencyAgents");
        }), []);
  var match$1 = state.search;
  var tmp = match$1 === "" ? undefined : state.search;
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var match$5 = state.sortBy;
  var agencyAgents = state.status;
  var tmp$1;
  if (typeof agencyAgents !== "object") {
    tmp$1 = agencyAgents === "FetchingAgencyAgents" ? JsxRuntime.jsx(TableRow.make, {
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Spinner.make, {
                              size: "XL",
                              color: "Teal"
                            }),
                        className: DashboardIndexCss.spinner
                      })
                })
          }) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardIndexCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "Something went wrong during the search"
                })
          });
  } else {
    var agencyAgents$1 = agencyAgents._0;
    tmp$1 = agencyAgents$1.length !== 0 ? Belt_Array.mapWithIndex(agencyAgents$1, (function (index, agencyAgent) {
              var match = agencyAgent.tsdId;
              var match$1 = agencyAgent.tsdName;
              return JsxRuntime.jsxs(TableRow.make, {
                          children: [
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: agencyAgent.userFirstName,
                                        className: DashboardIndexCss.tableLink,
                                        href: Routes_User.Dashboard.edit(agencyAgent.userId)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: agencyAgent.userLastName,
                                        className: DashboardIndexCss.tableLink,
                                        href: Routes_User.Dashboard.edit(agencyAgent.userId)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: agencyAgent.agencyName,
                                        className: DashboardIndexCss.tableLink,
                                        href: Routes_Agency.Dashboard.show(agencyAgent.agencyId)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: match !== undefined && match$1 !== undefined ? JsxRuntime.jsx("a", {
                                          children: match$1,
                                          className: DashboardIndexCss.tableLink,
                                          href: Routes_Tsd.Dashboard.show(Caml_option.valFromOption(match))
                                        }) : "No TSD connected."
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs(Popover.make, {
                                              children: [
                                                JsxRuntime.jsx(Popover.Trigger.make, {
                                                      children: JsxRuntime.jsx(Control.make, {
                                                            children: JsxRuntime.jsx(IconMenu.make, {
                                                                  size: "MD",
                                                                  color: "Teal"
                                                                })
                                                          })
                                                    }),
                                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                                      position: {
                                                        TAG: "Below",
                                                        _0: "RightEdge"
                                                      },
                                                      className: DashboardIndexCss.dropdownBody,
                                                      children: [
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: DashboardIndexCss.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "View",
                                                                    className: DashboardIndexCss.dropdownBodyLink,
                                                                    href: Routes_AgencyAgent.Dashboard.show(agencyAgent.id)
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: DashboardIndexCss.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Edit",
                                                                    className: DashboardIndexCss.dropdownBodyLink,
                                                                    href: Routes_AgencyAgent.Dashboard.edit(agencyAgent.id)
                                                                  })
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        className: DashboardIndexCss.menu
                                      })
                                })
                          ]
                        }, String(index));
            })) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardIndexCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "No agents were found."
                })
          });
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(DashboardAgencyAgentNavbar.make, {
                                currentLink: "Index"
                              }),
                          JsxRuntime.jsxs(Container.make, {
                                className: DashboardIndexCss.layout,
                                setRef: Caml_option.some(container),
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(H1.make, {
                                              className: DashboardIndexCss.title,
                                              children: "Manage Agents"
                                            }),
                                        className: DashboardIndexCss.header
                                      }),
                                  JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx(SearchField.make, {
                                                id: "agencies-search",
                                                value: state.search,
                                                placeholder: "Search by Name",
                                                inputClassName: DashboardIndexCss.searchField,
                                                onChange: (function ($$event) {
                                                    dispatch({
                                                          TAG: "UpdateSearchInput",
                                                          _0: $$event.target.value
                                                        });
                                                  })
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx(DashboardAgencyAgentsOptions.make, {
                                                      pageNum: state.currentPage,
                                                      query: tmp,
                                                      sortBy: state.sortBy,
                                                      toggleCsvModal: (function (param) {
                                                          dispatch("ToggleCsvModalOpen");
                                                        })
                                                    })
                                              })
                                        ],
                                        className: DashboardIndexCss.subHeader
                                      }),
                                  JsxRuntime.jsxs(Table.make, {
                                        className: DashboardIndexCss.table,
                                        children: [
                                          JsxRuntime.jsx(TableHeader.make, {
                                                children: JsxRuntime.jsxs(TableRow.make, {
                                                      children: [
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Control.make, {
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "SortBy",
                                                                              _0: "UserFirstName"
                                                                            });
                                                                      }),
                                                                    children: JsxRuntime.jsxs("span", {
                                                                          children: [
                                                                            "First Name",
                                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                                  size: "XXS",
                                                                                  direction: typeof match$2 === "object" && match$2.NAME === "UserFirstName" ? match$2.VAL : undefined
                                                                                })
                                                                          ],
                                                                          className: DashboardIndexCss.controlCell
                                                                        })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Control.make, {
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "SortBy",
                                                                              _0: "UserLastName"
                                                                            });
                                                                      }),
                                                                    children: JsxRuntime.jsxs("span", {
                                                                          children: [
                                                                            "Last Name",
                                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                                  size: "XXS",
                                                                                  direction: typeof match$3 === "object" && match$3.NAME === "UserLastName" ? match$3.VAL : undefined
                                                                                })
                                                                          ],
                                                                          className: DashboardIndexCss.controlCell
                                                                        })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Control.make, {
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "SortBy",
                                                                              _0: "AgencyName"
                                                                            });
                                                                      }),
                                                                    children: JsxRuntime.jsxs("span", {
                                                                          children: [
                                                                            "Agency Name",
                                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                                  size: "XXS",
                                                                                  direction: typeof match$4 === "object" && match$4.NAME === "AgencyName" ? match$4.VAL : undefined
                                                                                })
                                                                          ],
                                                                          className: DashboardIndexCss.controlCell
                                                                        })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Control.make, {
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "SortBy",
                                                                              _0: "TsdName"
                                                                            });
                                                                      }),
                                                                    children: JsxRuntime.jsxs("span", {
                                                                          children: [
                                                                            "TSD Name",
                                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                                  size: "XXS",
                                                                                  direction: typeof match$5 === "object" && match$5.NAME === "TsdName" ? match$5.VAL : undefined
                                                                                })
                                                                          ],
                                                                          className: DashboardIndexCss.controlCell
                                                                        })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: ""
                                                            })
                                                      ]
                                                    })
                                              }),
                                          JsxRuntime.jsx(TableBody.make, {
                                                children: tmp$1
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Pagination.make, {
                                              currentPage: state.currentPage,
                                              totalPages: state.totalPages,
                                              onPageClick: (function (x) {
                                                  dispatch({
                                                        TAG: "UpdatePage",
                                                        _0: x
                                                      });
                                                })
                                            }),
                                        className: DashboardIndexCss.pagination
                                      })
                                ]
                              }),
                          state.csvModalOpen ? JsxRuntime.jsx(DashboardExportModal.make, {
                                  title: "Agents CSV Export",
                                  message: "Please check your email for your agents export.",
                                  buttonText: "Close",
                                  onClick: (function (param) {
                                      dispatch("ToggleCsvModalOpen");
                                    })
                                }) : null
                        ],
                        className: DashboardIndexCss.pageContainer
                      }))
            });
}

var Css;

var $$default = DashboardAgencyAgentsIndex$default;

export {
  Css ,
  initialState ,
  $$default as default,
}
/* H1 Not a pure module */
