// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          userId: js.userId,
          agencyId: js.agencyId,
          isPrimary: js.isPrimary,
          createdAt: js.createdAt,
          updatedAt: js.updatedAt
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              userId: field.required("userId", ID.decoder),
              agencyId: field.required("agencyId", ID.decoder),
              isPrimary: field.required("isPrimary", Json_Decode$JsonCombinators.bool),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function fromJs$1(js) {
  return {
          id: js.id,
          userId: js.userId,
          userFirstName: js.userFirstName,
          userLastName: js.userLastName,
          agencyId: js.agencyId,
          agencyName: js.agencyName,
          tsdId: Belt_Option.map(Caml_option.nullable_to_opt(js.tsdId), (function (prim) {
                  return prim;
                })),
          tsdName: Caml_option.nullable_to_opt(js.tsdName)
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              userId: field.required("userId", ID.decoder),
              userFirstName: field.required("userFirstName", Json_Decode$JsonCombinators.string),
              userLastName: field.required("userLastName", Json_Decode$JsonCombinators.string),
              agencyId: field.required("agencyId", ID.decoder),
              agencyName: field.required("agencyName", Json_Decode$JsonCombinators.string),
              tsdId: field.required("tsdId", Json_Decode$JsonCombinators.option(ID.decoder)),
              tsdName: field.required("tsdName", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

function fromJs$2(js) {
  return {
          agencyAgents: Belt_Array.map(js.agencyAgents, fromJs$1),
          totalAgencyAgents: js.totalAgencyAgents,
          totalPages: js.totalPages,
          currentPage: js.currentPage,
          perPage: js.perPage
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              agencyAgents: field.required("agencyAgents", Json_Decode$JsonCombinators.array(decoder$1)),
              totalAgencyAgents: field.required("totalAgencyAgents", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int)
            };
    });

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

function page(pageNum) {
  return "page=" + String(pageNum);
}

function query(queryString) {
  return Belt_Option.getWithDefault(Belt_Option.map(queryString, (function (x) {
                    return "&query=" + encodeURIComponent(x);
                  })), "");
}

function sortBy(sortBy$1) {
  var variant = sortBy$1.NAME;
  if (variant === "TsdName") {
    return "&sort_by=tsds.name&sort_direction=" + toString(sortBy$1.VAL);
  } else if (variant === "AgencyName") {
    return "&sort_by=agencies.name&sort_direction=" + toString(sortBy$1.VAL);
  } else if (variant === "UserLastName") {
    return "&sort_by=users.last_name&sort_direction=" + toString(sortBy$1.VAL);
  } else {
    return "&sort_by=users.first_name&sort_direction=" + toString(sortBy$1.VAL);
  }
}

var Index = {
  fromJs: fromJs$2,
  decoder: decoder$2,
  SortDirection: SortDirection,
  SortBy: SortBy,
  page: page,
  query: query,
  sortBy: sortBy
};

var Dashboard = {
  fromJs: fromJs$1,
  decoder: decoder$1,
  fromJson: fromJson$1,
  Index: Index
};

export {
  fromJs ,
  decoder ,
  fromJson ,
  Dashboard ,
}
/* decoder Not a pure module */
