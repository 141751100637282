// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FetchAgencyAgentsDashboardRequest from "./requests/FetchAgencyAgentsDashboardRequest.res.js";

var Dashboard = {
  index: FetchAgencyAgentsDashboardRequest.exec
};

export {
  Dashboard ,
}
/* FetchAgencyAgentsDashboardRequest Not a pure module */
